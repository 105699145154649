<template lang="pug">
  .tab-main-container
    .headline 登攀したルート一覧画面
    .custom-control.custom-checkbox.mb-3.mt-3(v-if="this.isCheckin")
      input#climbRoute.custom-control-input(type="checkbox" v-model="climbRoute" @click="routeComplete" v-bind:disabled="isDisabled")
      label.custom-control-label(for="climbRoute") 完登した記録のみ表示する
    .text-center
      b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
    div(v-if="!isLoading")
      #search-results(v-if="routeList.length == 0") {{ this.message }}
      .rock-table-wrapper(v-else)
        .rock-table
          b-table(striped hover small borderless selectable :items="routeList" :fields="fields" :tbody-tr-class="rowClass" @row-selected="onRowSelected")
</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      message: '',
      isDisabled: false,
      isLoading: true,
      climbRoute: false,
      fields: [
        {
          key: 'rocky',
          label: '岩場',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white'}
        },
        {
          key: 'rock',
          label: '岩',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white'}
        },
        {
          key: 'route',
          label: 'ルート名',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white'}
        },
        {
          key: 'level',
          label: 'level',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white'}
        }
      ],
      routeList: [],
      isCheckin: false,
    }
  },
  async created() {
    await this.getRouteRecordList(false)
  },
  // メソッド
  methods: {
    async routeComplete() {
      this.isDisabled = true
      await this.getRouteRecordList(this.climbRoute ? false : true)
    },
    rowClass(item, type) {
      if (!item) return
      if (item.status === 'Climbed') return 'table-info'
    },
    onRowSelected(items) {
      const rockyId = items[0].rockyId
      const rockId = items[0].rockId
      const routeId = items[0].routeId
      //ルート詳細ページ遷移
      this.$router.push({ name: 'myPage_:userId_routeDetail', params: { userId: this.$route.params.userId }, query: { rockyId: rockyId, rockId: rockId, routeId: routeId} })
    },
    //ルート取得
    async getRouteRecordList(completeFlag){
      this.isLoading = true

      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-record'
      let wkRouteList = []
      this.routeList = []
      await axios
        .get(jsonUrl,{
          params: {
            'targetUserId': this.$route.params.userId,
            'type': 'allRouteList',
            'completeFlag': completeFlag,
          },         
          headers: {
            'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
          }
        })
        .then(response => {
          if(response.data == null) {
            //チェックイン履歴とれなかった場合
            this.message = '非公開'
            this.isCheckin = false
          }else if(response.data.length == 0) {
            //チェックイン履歴0件の場合
            this.message = '記録がありません'
            this.isCheckin = false
          }else if(response.data.length > 0){
            //チェックイン履歴ある場合
            wkRouteList = response.data
            this.isCheckin = true
          }
          this.isLoading = false
          this.isDisabled = false
        })
        .catch(err => {
          this.isLoading = false
          this.message = '情報の取得に失敗しました'
          return
        })

      let count = 0
      wkRouteList.forEach(routeInfo => {
        let isActiveFlg = false
        let status
        ++count
        if(routeInfo.date==''){
          routeInfo.date = '記録無し'
        }else{
          isActiveFlg = true
          status= 'Climbed'
        }
        this.routeList.push({
          no: count,
          rockyId:routeInfo.rockyId,
          rockId:routeInfo.rockId,
          routeId: routeInfo.routeId,
          rocky: routeInfo.rockyName,
          rock: routeInfo.rockName,
          route: routeInfo.routeName,
          level: routeInfo.gradeName
        })
      })
    },
  },
  // 算出プロパティ
  computed: {
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
#search-results
  margin 1rem
</style>
